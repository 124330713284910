@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto:ital,wght@0,100;0,300;1,100&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;



body{
    @apply overflow-x-hidden;
    font-family: 'Poppins',sans-serif;
}

@keyframes fadeinanim {
    0%{
        opacity: 0;
    }

    100%{
        opacity: 1;
    }
}

@keyframes fadeoutanim {
    0%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

.fadeIn{
    animation: fadeinanim 500ms;
}

.fadeOut{
    animation: fadeoutanim 500ms;
}

@keyframes logoAnim {
    0%{
        transform: translateX(0);
    }

    100%{
        transform: translateX(-150px);
    }
}

@keyframes vendorAnim {
    0%{
        transform: translateX(0);
    }

    60%{
        transform: translateY(0) translateX(150px);
    }

    100%{
        transform: translateY(-20px) translateX(150px);
    }
}

@keyframes barbieAnim {
    0%{
        transform: translateY(0);
    }

    40%{
        transform: translateY(0);
    }

    100%{
        transform: translateY(60px);
    }
}

@keyframes startOutAnim {
    0%{
        opacity: 1;
    }

    80%{
        opacity: 1;
    }

    100%{
        opacity: 0;
    }
}

.startLogo{
    animation: logoAnim 2s forwards;
}
.startVendor{
    animation: vendorAnim 2s forwards;
}

.startBarbie{
    animation: barbieAnim 3s forwards;
}

.startOut{
    animation: startOutAnim 5s forwards;
}
